#gallery.app__flex{
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__gallery{
  // border: 2px solid seagreen;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__gallery-cont {
  width: 85%;
  max-width: 100svw;
  padding-top: 2rem;
  padding-right: 1.5rem;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // border: 1px solid red;
}

.slider-container {
  border: rgb(53, 53, 53) 0.1rem solid;
  border-color: rgb(163, 163, 163);
  border-radius: 2px;
}


.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: var(--blueAccColor) !important;
}

.slick-dots li button::before {
  opacity: 0.8 !important;
  font-size: 1.2rem !important;
  line-height: 40px !important;
  color: var(--lightIndigoColor) !important;
}

.slick-prev, .slick-next{
  z-index: 9;
}

.gallery-item {
  // border: 3px dotted grey;
  position: relative;
  cursor: grab;
  img {
    margin: auto !important;
    // display: block;
    height: 60svh;
    @media screen and (max-width: 900px) {
      height: 45svh;
    };
    // phone landscape tbh
    @media only screen 
    and (min-device-width: 900px) 
    and (max-device-width: 1366px) 
    and (-webkit-min-device-pixel-ratio: 1) 
    { //ipad
      height: 45svh;
      // border: 3px dotted grey;
    }
  }
};

.gallery-desc {
  position: absolute; //og 
  // position: relative;
  backdrop-filter:blur(50px);
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0.62rem;
  color: var(--coolGrey);
  // border: 1px solid red;
  @media screen and (min-device-width: 300px) and (max-device-width: 680px){ //before: 500
    max-height: 100%;
    overflow: scroll; //og
    font-size: 0.8em;
  }
}

.show-more-btn {
  background: var(--lightIndigoColor);

  display: none;
  visibility: hidden;
  position: relative;
  top: 3em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;

  padding: 0.45em;
  border-radius: 2rem;

  font-size: 1rem !important;
  line-height: normal !important;

  @media screen and (max-width: 950px) and (orientation: portrait) {
    display: block;
    visibility: visible;
  }
  
  @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 1000px) 
  and (orientation: landscape) {
    display: block;
    visibility: visible;
    // border: 2px solid green;
  }
  // ipad
  @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1366px) 
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 1) {
    display: block;
    visibility: visible;
    // border: 2px solid purple;
  }

  @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width:  1366px) and (orientation: portrait) {
    display: block;
    visibility: visible;
    // border: 2px solid palevioletred;
  }
}
