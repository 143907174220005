// .app__education {
//     padding: 2rem 0.4rem; //text padding
//     margin: 0 1rem;
//     background-color: rgba(202,204,203, 0.4); //coolgrey
//     box-shadow: 0px 2px 15px #322f56CC;
//     border-radius: 2rem;
//     width: 85%;
// }

.edu_head-text {
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;  
    .head-text{
        background-color: rgba(202,204,203, 0.4);
        border-radius: 2rem;
        padding: 1rem 3rem;
    }
    
}

.app__education-container {
    // border: rgb(228, 104, 104) 0.1rem solid;
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
    flex-direction: row;
    @media screen and (max-width: 900px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.app__education-item{
    display: flex;
    padding: 1rem;
    background-color: var(--coolGrey);
    border-radius: 2rem;
    border: rgb(53, 53, 53) 0.1rem solid;
    border-color: rgb(163, 163, 163);
    box-shadow: 0px 2px 10px #322f56CC; //#7f83d6CC
    text-align: center;
    // flex-wrap: wrap;
    flex-direction: row;
    width: calc((90% / 3) - 2rem);
    align-items: center;
    justify-content: center;
    p{font-size: 1rem;}

    span {
        display: flex;
        font-size: 2.2em;
        align-items: center;
        color: var(--mutedDarkPurpleColor);
        padding-right: 0.5rem;
    }

    @media screen and (max-width: 900px) {
        width: 72%;
        span {
            margin-right: 10px;
        }
    }
}
