/* @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,500;1,600;1,700&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Englebert&family=Grandstander:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Nunito+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,500;1,600;1,700&display=swap');
/* @import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css"; */

:root {
  --font-base: "Nunito Sans", "Grandstander", "Englebert", Seravek, 'Gill Sans Nova', source-sans-pro, sans-serif;

  --mutedDarkGrey: #585455;
  --mutedDarkPurpleColor: #322f56;

  --offBlackColor: #180b01;
  --lightSandColor: #d8c8be;
  --lightGreyBlueColor: #c0d1e1;
  --coolGrey: rgb(202, 204, 203);

  --blueAccColor: #36bdc2;
  --mediumIndigoColor: #404b97;
  --indigoColor: #7f83d6;
  --lightIndigoColor: #b3b1ce;
  
  --salmonAccColor: #fd7f81;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  /* PRESERVE 3D does affects my nav bar! dont use it*/
}