.app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between; //og 
    // justify-content: stretch; 
    align-items: center;

    padding: 0.5rem 2rem 0rem;
    background: rgba(255, 255, 255, 0.45);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    // not avail on all browsers
    border: 1px solid rgba(255, 255, 255, 0.18);
    position: fixed;
    z-index: 99;
}

.app__navbar-logo {
    display: flex;
    justify-content: center;
    align-items: baseline;
    img {// width: 90px;// height: 30px;
        height: 2.7rem;
        @media screen and (min-width: 1824px) {
            width: 180px;
            height: 40px;
        };
    }
}

.app__navbar-links {
    flex: 1; //take up the rest of the space the logo doesn't take
    display: flex; //makes it a row
    justify-content: center;
    // justify-content: space-evenly;
    align-items: center;
    list-style: none;
    li {
        margin: 0 1rem; //og
        cursor: pointer;
        flex-direction: column;
        font-size: 0.9rem;
        padding: 0.33rem;
        text-align: center !important;
        // border: 1px solid red;
        &:hover {
            border: 2px solid var(--indigoColor);
            div {
                background: var(--salmonAccColor);
            }
        };
        a {
            color: var(--mutedDarkGrey);
            text-decoration: none;
            flex-direction: column;
            text-transform: uppercase;
            font-weight: 500;
            transition: all 0.2s ease-in-out;
            &:hover {
                color: var(--indigoColor);
                font-weight: 700;
            }
        }
    }
    @media screen and (max-width: 950px) {
        display: none;
    };
}

.app__navbar-menu {
    // width: 35px;
    // height: 35px;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    z-index: 99;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: transparent, var(--offBlackColor); 
    background-color: var(--mediumIndigoColor); 
    svg {
        color: var(--lightGreyBlueColor); //todo
    }
    div {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        padding: 1rem;
        width: 80%;
        height: 100svh;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        background-color: var(--indigoColor);
        // box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);
        svg {
            margin: 0.5rem 1rem;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            z-index: 9;
        }
        li {
            margin: 1rem;
            a {
                color: var(--lightGreyBlueColor);
                text-decoration: none;
                font-size: medium;
                text-transform: uppercase;

                transition: all 0.3s ease-in-out;
                &:hover {
                    color: var(--mutedDarkPurpleColor);
                }
            }
        }
        @media screen 
        and (min-width: 950px) 
        and (orientation: portrait)
        {
            display: none;
        }
    }; //end div
    @media screen and (min-width: 952px) { //  and (orientation: portrait)
        display: none;
    }; //end menu

    @media screen 
    and (min-device-width: 305px) 
    and (max-device-width: 950px) 
    and (orientation: landscape) 
    { 
        div {
            height: 100svh;
            width: 65%;
            overflow: scroll;
            li {
                margin: 1.2%;
            };
            svg {
                margin: 0.8rem 1rem;
            };
        };
    }; //end media 

    @media screen 
    and (min-device-width: 305px) 
    and (max-device-width: 950px) 
    and (orientation: portrait) 
    {
        div {
            height: 100svh;
            width: 100%;
        }
    }
};