.app__about {
    // flex: 1;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: center;
    // align-items: center;
    // border: 1px solid green;
    padding: 2rem 2rem; //text padding
    margin: 0 1rem;
    background-color: rgba(203, 202, 204, 0.34);
    box-shadow: 0px 2px 15px #322f56CC;
    border-radius: 2rem;
    isolation: isolate;
    @media screen and (max-width: 950px) {
        padding: 1rem 0.5rem;
        margin: 1rem 1rem;
    }
}

.abt_head-text{
    // margin: 1rem 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    @media screen and (max-device-width: 950px) {
        margin: 1.5rem 0 0 0;
    }
}

.app__about-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.app__abt-desc {
    padding: 2rem 3rem;
    flex: 2;
    border-radius: 15px;
    margin-right: 2em;
    // box-shadow: 0px 2px 20px #322f56CC;
    // text-shadow: white 0px 0px 15px;
    p{
        mix-blend-mode: multiply;
        text-shadow: 0 0.4px 0 var(--mediumIndigoColor), var(--lightGreyBlueColor) 0px 0px 10px;
        @media screen and (min-width: 1224px){
            font-size: 1.33rem;
        }
    }
    @media screen and (max-width: 950px) {
        padding: 1rem;
        margin-right: 0;
    }
}

.app__about-profile {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2em;
    @media screen and (max-width: 950px) {
        margin-left: 0.3rem;
        img { width: 86%;}
    }
}

.app__about-img {
    width: 80%;
    max-width: 100%;
    border-radius: 50px;
    box-shadow: 0px 2px 20px #322f56CC;
    // margin-top: 2rem; //can remove? 
    @media screen and (max-width: 480px) { //480
        margin-top: 2rem;
    }
}

@media screen and (max-width: 768px) {
    .app__about-container {
        flex-direction: column; 
    }
    .app__abt-desc,
    .app__about-profile {
        margin: auto;
        margin-top: 1rem;
    }
}
