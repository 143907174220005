// #work{ padding-top: 2rem; }
// .app__works{
//     background-color: rgba(202,204,203, 0.2); //coolgrey
//     box-shadow: 0px 2px 15px #322f56CC;
//     border-radius: 2rem;
//     width: 90%;
//     margin: 4em 0;
// }

.work_head-text{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    .head-text{
        // background-color: transparent;
        background-color: rgba(202,204,203, 0.4);
        border-radius: 2rem;
        padding: 1rem 3rem;
        // border: var(--indigoColor) 2px solid;
    }
}

.app__work-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
        // object-fit: contain;
        border-radius: 50%;
        background-clip: padding-box;
    }
}

.app__work-timeline {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    // border: green 2px solid;
    .vtedate{
        width: max-content !important;
        font-weight: bold;
        padding: 0.5rem;
        background-color: var(--lightGreyBlueColor);
        border-radius: 2rem;
        // border: rgb(163, 163, 163) 0.1rem solid;
    };
}

.app__work-head {
    padding: 1rem;
    text-align: center;
    h3 {
        font-size: 1.5rem;
        font-weight: 900;
        color: var(--mutedDarkPurpleColor);
    }
    p{
        font-style: italic;
        font-size: 0.9rem;
        font-weight: 100;
        margin-top: 0;
        color: var(--mutedDarkPurpleColor);
    }
}

.app__work-list {
    padding: 0 2rem 0.6rem;
    li {
        letter-spacing: 0.02em;
        font-size: inherit;
        color: var(--mutedDarkPurpleColor);
        // font-size: 1.2rem;
    }
}
