:root {
    --tooltip-text-color: white;
    --tooltip-background-color: black;
    --tooltip-margin: 2.2rem;
    --tooltip-arrow-size: 6px;
}

.Tooltip-Wrapper {
    display: inline-block;
    position: relative;
}


// absolute positioning
.Tooltip-Tip {
    position: absolute;
    border-radius: 10px;
    left: 50%;
    transform: translateX(-50%);
    animation: opacity 0.4s 1;
    //  translate(-50%, 0)
    // padding: 6px;
    padding: .7rem;
    color: var(--tooltip-text-color);
    background: var(--tooltip-background-color);
    font-size: 14px;
    line-height: 1;
    z-index: 5;
    // white-space: nowrap;
    white-space: pre-line;

}

// css border triangles
.Tooltip-Tip::before {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
    margin-left: calc(var(--tooltip-arrow-size) * -1);
}


// absolute TOP
.Tooltip-Tip.top {
    top: calc(var(--tooltip-margin) * -1);
}

// border triangles
.Tooltip-Tip.top::before {
    top: 100%;
    // left: 35%;
    border-top-color: var(--tooltip-background-color);
}



// absolute RIGHT
.Tooltip-Tip.right {
    left: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
}

// border triangles
.Tooltip-Tip.right::before {
    left: calc(var(--tooltip-arrow-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: var(--tooltip-background-color);
}


// absolute BOTTOM
.Tooltip-Tip.bottom {
    bottom: calc(var(--tooltip-margin) * -1);
}

// border triangles
.Tooltip-Tip.bottom::before {
    bottom: 100%;
    border-bottom-color: var(--tooltip-background-color);
}


// absolute LEFT
.Tooltip-Tip.left {
    left: auto;
    right: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
}

// border triangles
.Tooltip-Tip.left::before {
    left: auto;
    right: calc(var(--tooltip-arrow-size) * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: var(--tooltip-background-color);
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}


@keyframes pulse {
    0%, 100% {
        background-color: yellow;
    }
    50% {
        background-color: red;
    }
}

@keyframes opacity {
    0% { opacity: 0; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}


@keyframes slide-in {
    0% {transform: translateY(-100px);}
    100% {transform: translateY(0);}
}