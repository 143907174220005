#contact > .app__wrapper {
    min-height: 70svh;
    // height: 70svh;
    max-height: 80svh;
}
#contact {
    position: relative;
}
.footer__copyright {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
};

.footer__parent-cont{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 3rem;
    padding: 1rem;
    // border: 2px dotted turquoise;
    // .ftr-hd>.head-text, .ftr-hd>.p-text{
    .ftr-hd>.head-text+.p-text{
        margin: 1rem 0;
        text-align: center;
    };
}

.footer_img-container {
    // width: 200px;
    width: 14%;
    height: fit-content;
    .tilt-card {
        // min-height: 50% !important;
        height: min-content !important;
        background-color: transparent !important;
    }
    .footer-img {
        width: 100%;
        border-radius: 2rem;
        border: rgb(53, 53, 53) 0.1rem solid;
        border-color: rgb(163, 163, 163);
        box-shadow: 0px 2px 10px #7f83d6CC;
        background-color: rgba(234, 219, 202, 0.3);
    }
}


.contact__info-box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 2rem;
    border: rgb(53, 53, 53) 0.1rem solid;
    border-color: rgb(163, 163, 163);
    box-shadow: 0px 2px 10px #7f83d6CC;
    background-color: rgba(234, 219, 202, 0.3);
    margin: 0 1rem;
    padding: 0.5rem;
    text-align: center;
    a {
        text-decoration: none;
    };
};

.contact-info__item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin: 1rem;
    // border: 1px dashed hotpink;
    position: relative;
    .p-text, a, p {
        line-height: 0px !important;
        font-size: 1.5rem;
        font-weight: 600;
        transition: color 200ms ease-in;
    }
    .p-text:hover, a:hover {
        color: var(--indigoColor);
        text-decoration: underline;
        text-underline-offset: 0.5rem;
    }
    .info__icon {
        font-size: 1.5rem;
        color: var(--mutedDarkPurpleColor);
    }
};

.contact-info__item .Tooltip-Tip{
    background-color: var(--blueAccColor) !important;
    transform: translateY(-15px);
    left: -1px;
    white-space: nowrap;
};

.contact-info__item .Tooltip-Tip::before {
    border-top-color:var(--blueAccColor);
};


@media screen and (max-device-width: 950px) and (orientation: portrait) {
    #contact > .app__wrapper {
        min-height: 100svh;
    }
    .footer__parent-cont{
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        margin: 0 4%;
    }
};
@media screen and (max-device-width: 950px) and (orientation: landscape) {
    #contact > .app__wrapper {
        min-height: 100svh;
    }
    .footer__parent-cont {
        flex-wrap: nowrap;
        align-items: center;
        flex-direction: column-reverse;
        margin-bottom: 0;
        .ftr-hd> .p-text{
            font-size: 1.5rem;
        };
    }
    .footer_img-container{
        // width: 100px;
        display: none;
    }
};