.example-container-tilt {
    display: grid;
    border: 2px dashed red;
    height: 80vh;
    width: 100%;
    place-content: center;
    padding: 5rem 2rem;
    color: black;
}
    // position: relative;
    // height: 24rem;
    // width: 18rem; 
    // height: 30rem;
    // width: 30rem;
    // border-radius: 0.75rem;

.tilt-card {
    // background-color: #a5b4fc;
    background-color: var(--salmonAccColor);
}